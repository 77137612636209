import React from "react";
import Carousel from "react-multi-carousel";
import { Image } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import "react-multi-carousel/lib/styles.css";
import "./sli.css";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    paritialVisibilityGutter: 60,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    paritialVisibilityGutter: 50,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    paritialVisibilityGutter: 30,
  },
};

// Because this is an inframe, so the SSR mode doesn't not do well here.
// It will work on real devices.
const SlickSlider = (props) => {
  return (
    <Carousel
      ssr
      partialVisbile
      deviceType={"desktop"}
      itemClass="image-item"
      responsive={responsive}
    >
      {props.itemss.map((image, key) => {
        return (
          <div key={key}>
            <a target="_blank" rel="noreferrer" href={image.link}>
            <Image
              draggable={false}
              style={{
                width: "100%",
                height: "230px",
                borderRadius: "25px",
                objectFit: "cover",
              }}
              src={image.image}
            />
            </a>
            <div className="iamge_car_text">
              <a target="_blank"  rel="noreferrer" href={image.link}><h4 className="serhelp my-2">{image.title.substring(0, 25) + "..."}</h4></a>
              <h5>{image?.companyName}</h5>
            </div>
          </div>
        );
      })}
    </Carousel>
  );
};

export default SlickSlider;
